import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Navigation from 'components/Navigation';

import useSiteMetadata from 'components/SeoHelmet/useSiteMetadata';

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});

const Header = ({ classes }) => {
  const { title } = useSiteMetadata();
  return (
    <header className={classes.container}>
      <Navigation title={title} />
    </header>
  );
};
Header.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(styles)(Header);
