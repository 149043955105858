import React from 'react';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import {
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from '@material-ui/core';

import { FaBars } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

const styles = ({ palette, typography }) => ({
  navLink: {
    border: 'none',
    display: 'inline-block',
    textDecoration: `none`,
    color: palette.secondary.main,
    borderRadius: typography.pxToRem(6),
    '&:hover': {
      backgroundColor: palette.color.paleGray,
      color: palette.color.paleGreen,
    },
    '&:focus': {
      backgroundColor: palette.color.paleGreen,
    },
  },
  closeIcon: {
    position: 'fixed',
    left: 240,
    top: 50,
    backgroundColor: palette.common.white,
    zIndex: 1200,
    borderRadius: 10,
  },
  listItemText: {
    fontSize: typography.pxToRem(22),
  },
  listNavigation: {
    width: 250,
    padding: `${typography.pxToRem(24)} ${typography.pxToRem(18)}`,
  },
  navLinkActive: {
    fontWeight: 500,
    color: palette.primary.main,
    backgroundColor: palette.color.paleGreen,
  },
  fillSpace: {
    height: '100%',
  },
  divider: {
    margin: '1.125em 0',
  },
  navLinkConditii: {
    border: 'none',
    display: 'inline-block',
    textDecoration: `none`,
    color: palette.secondary.main,
    fontSize: typography.pxToRem(14),
  },
});

const MobileNavigation = ({ classes, options, onClick, open }) => (
  <>
    <IconButton onClick={onClick} color="inherit">
      <FaBars />
    </IconButton>
    <SwipeableDrawer
      open={open}
      onClose={() => onClick(false)}
      onOpen={() => onClick(true)}>
      <List className={classes.listNavigation}>
        <ListItem>Logo</ListItem>
        <Divider variant="fullWidth" className={classes.divider} />
        {options.map(link => (
          <ListItem
            key={`${link.to}-${link.text}`}
            onClick={onClick}
            component={Link}
            to={link.to}
            className={classes.navLink}
            activeClassName={classes.navLinkActive}
            exact="true">
            <ListItemText
              disableTypography
              primary={link.text}
              classes={{ root: classes.listItemText }}
            />
          </ListItem>
        ))}
        <div className={classes.fillSpace} />
        <ListItem
          to="/termeni-si-conditii"
          className={classes.navLinkConditii}
          onClick={onClick}
          component={Link}>
          Termeni și Condiții
        </ListItem>
      </List>
      <Box className={classes.closeIcon}>
        <IconButton onClick={onClick} component={Link} to={options[0].to}>
          <GrClose color="primary" />
        </IconButton>
      </Box>
    </SwipeableDrawer>
  </>
);

MobileNavigation.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(MobileNavigation);
