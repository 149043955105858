import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import { Fade, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import Header from 'components/Header';
import Footer from 'components/Footer';

import LayoutTitle from './LayoutTitle';

import styles from './style';

export { LayoutTitle };

const Layout = ({ classes, children, footerNode, disabledContentWrap }) => (
  <>
    <div className={classes.content}>
      <Header />
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        renderView={props => <div {...props} className={classes.scrollFix} />}>
        <Fade in mountOnEnter unmountOnExit>
          <Container
            className={classNames({
              [classes.container]: !disabledContentWrap,
            })}>
            {children}
          </Container>
        </Fade>
        {footerNode}
      </Scrollbars>
    </div>
  </>
);

Layout.propTypes = {
  classes: PropTypes.object,
  footerNode: PropTypes.node,
  disabledContentWrap: PropTypes.bool,
  children: PropTypes.node,
};

Layout.defaultProps = {
  footerNode: <Footer />,
  disabledContentWrap: false,
};

export default withStyles(styles)(Layout);
