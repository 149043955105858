const styles = ({ palette, typography }) => ({
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  linkText: {
    textDecoration: `none`,
    padding: `${typography.pxToRem(8)} ${typography.pxToRem(8)}`,
  },
  listItemIcon: {
    minWidth: 30,
  },
  navLinkActive: {
    fontWeight: 500,
    borderBottom: `${typography.pxToRem(2)} solid ${palette.primary.main}`,
  },
});

export default styles;
