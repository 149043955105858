import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Menu, ListItem, ListItemText } from '@material-ui/core';

import styles from './styles';

const StyledMenu = withStyles({
  paper: {},
})(props => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

function DesktopMenu(props) {
  const { children, title, classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem
        button
        aria-controls="customized-menu"
        aria-haspopup="true"
        className={classes.linkText}
        activeClassName={classes.navLinkActive}
        exact="true"
        onClick={handleClick}>
        <ListItemText primary={title} />
      </ListItem>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {children}
      </StyledMenu>
    </>
  );
}
DesktopMenu.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default withStyles(styles)(DesktopMenu);
