import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import { withStyles } from '@material-ui/styles';
import {
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  ListItem,
} from '@material-ui/core';

import DesktopMenu from './DesktopMenu';

import { types } from './roots';

import styles from './styles';

const DesktopNavigation = ({ classes, options }) => (
  <List
    component="nav"
    aria-labelledby="main navigation"
    className={classes.navDisplayFlex}>
    {options.map(link => {
      const { type, links } = link;
      if (type === types.MENU) {
        return (
          <DesktopMenu title={link.text}>
            {links.map(item => (
              <MenuItem
                component={Link}
                to={item.to}
                exact="true"
                key={item.to}>
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </MenuItem>
            ))}
          </DesktopMenu>
        );
      }

      return (
        <ListItem
          button
          key={`${link.to}-${link.text}`}
          to={link.to}
          className={classes.linkText}
          activeClassName={classes.navLinkActive}
          exact="true"
          component={Link}>
          <ListItemText primary={link.text} />
        </ListItem>
      );
    })}
  </List>
);

DesktopNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(DesktopNavigation);
