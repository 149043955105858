export const types = {
  MENU: 'menu',
  LINK: 'link',
};
export const options = [
  { type: types.link, to: '/', text: 'Home' },
  { type: types.link, to: '/echipament', text: 'Echipament' },
  { type: types.link, to: '/portofoliu', text: 'Portofoliu' },
  { type: types.link, to: '/contact', text: 'Contact' },
];
