import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { connect } from 'react-redux';
import { withWidth, withStyles, AppBar, Toolbar } from '@material-ui/core';

import { isMobileView } from 'utils/screen';
import compose from 'utils/compose';

import { selectToggleMenuState } from 'state/navigation/selectors';
import { toggleMenu } from 'state/navigation/actions';

import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

import { options } from './roots';

const styles = ({ palette, typography }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    zIndex: 10,
  },
  logo: {
    border: 'none',
    float: 'left',
    transition: 'all .3s',
    textDecoration: 'none',
    marginRight: 'auto',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: typography.pxToRem(22),
    color: palette.primary.contrastText,
  },
});

const Navigation = ({ classes, width, isOpen, dispatchToggleMenu, title }) => (
  <AppBar position="static" elevation={0}>
    <Toolbar>
      {isMobileView(width) ? (
        <>
          <MobileNavigation
            options={options}
            open={isOpen}
            onClick={dispatchToggleMenu}
          />
          <Link to="/" className={classes.logo}>
            {title}
          </Link>
        </>
      ) : (
        <>
          <Link to="/" className={classes.logo}>
            {title}
          </Link>
          <DesktopNavigation options={options} />
        </>
      )}
    </Toolbar>
  </AppBar>
);

Navigation.defaultProps = {
  isOpen: false,
  dispatchToggleMenu: () => {},
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  dispatchToggleMenu: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  isOpen: selectToggleMenuState(state, props),
});

const mapDispatchToProps = dispatch => ({
  dispatchToggleMenu: action => dispatch(toggleMenu(action)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withWidth(),
  withStyles(styles),
)(Navigation);
