import { createSelector } from 'reselect';

const navigationSelector = state => state.nav;
const selectToggleMenuState = createSelector(
  navigationSelector,
  state => state.open,
);

export { selectToggleMenuState };

// const getStateOpen = state => {
//   console.log('xxxxxxxxxxxxxxxxxxxxxxxxx', state);
//   return state.navigation;
// };
//
// const navigation = state => state.navigation.items
//
// export const selectToggleMenuState = () => {
//   createSelector([getStateOpen], state => {
//     debugger;
//     return state.open;
//   });
//   return getStateOpen().open
// }
