import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import seoImage from 'images/seo_image.jpg';

import useSiteMetadata from './useSiteMetadata';

function SeoHelmet({ description, lang, meta, title, keywords }) {
  const siteMetadata = useSiteMetadata();
  const metaDescription = description || siteMetadata.description;
  const metaKeywords = {
    name: `keywords`,
    content: (keywords?.length ? keywords : siteMetadata.keywords).join(`, `),
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteMetadata.siteUrl,
        },
        {
          property: `og:image`,
          content: seoImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:url`,
          content: siteMetadata.siteUrl,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          property: `twitter:image`,
          content: seoImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(metaKeywords)
        .concat(meta)}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
    </Helmet>
  );
}

SeoHelmet.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.array,
  title: PropTypes.string,
};

SeoHelmet.defaultProps = {
  lang: `ro`,
  meta: [],
  keywords: [],
  description: ``,
};

export default SeoHelmet;
