import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Link from 'gatsby-link';
import { Typography, Container } from '@material-ui/core';

import useSiteMetadata from 'components/SeoHelmet/useSiteMetadata';

import color from 'styles/color';

const styles = ({ breakpoints, typography, palette, spacing }) => ({
  container: {
    margin: `${typography.pxToRem(8)} auto 0`,
    width: '100%',
    padding: spacing(4, 2),
    marginTop: 'auto',
    [breakpoints.up('md')]: {
      padding: spacing(3),
    },
  },
  copyRight: {
    color: palette.text.primary,
    backgroundColor: color.fade(palette.common.white, 0.6),
    padding: `3px ${spacing(1)}px`,
  },
  termeniLink: {
    color: palette.text.primary,
    marginLeft: 10,
  },
});

export const CopyRight = params => {
  const { children, title, className } = params;
  return (
    <Typography
      variant="caption"
      color="textPrimary"
      component="span"
      className={className}
      gutterBottom={false}>
      Copyright © {title} {new Date().getFullYear()}. Toate drepturile rezervate
      IRRIGATION & LANDFLOWER S.R.L.
      {children}
    </Typography>
  );
};

const Footer = ({ classes }) => {
  const siteMetadata = useSiteMetadata();
  return (
    <Container className={classes.container}>
      <CopyRight title={siteMetadata.title} className={classes.copyRight}>
        <Link className={classes.termeniLink} to="/termeni-si-conditii">
          Termeni și Condiții
        </Link>
      </CopyRight>
    </Container>
  );
};

Footer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Footer);
