
const styles = ({ breakpoints, spacing, palette }) => ({
  '@global': {
    html: {
      [breakpoints.up('xl')]: {
        fontSize: '0.833333vw !important',
      },
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#___gatsby': {
      height: '100%',
      width: '100%',
    },
    '#gatsby-focus-wrapper': {
      height: '100%',
      width: '100%',
    },
  },
  container: {
    paddingLeft: `${spacing(1.1)}px`,
    paddingRight: `${spacing(1.1) + 17}px`,
    maxWidth: 1100,
  },
  scrollFix: {
    marginRight: '-17px !important',
    marginBottom: '-17px !important',
  },
  content: {
    margin: 'auto',
    flex: 'auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.backgroundColor,
  },
});

export default styles;
