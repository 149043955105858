/**
 * Created by Alexandru Huszar on 4/13/2021.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Fade, Typography, withStyles } from '@material-ui/core';

export const styles = ({ spacing }) => ({
  layoutTitle: {
    paddingTop: spacing(3),
    marginBottom: spacing(2),
  },
});

/**
 * Title
 */
function LayoutTitle(props) {
  const { classes, title } = props;
  /**
   * Render the component
   *
   * @return {JSX.Element}
   */
  return (
    <Fade in timeout={1000}>
      <Typography
        variant="h3"
        color="textPrimary"
        gutterBottom
        variantMapping={{ h5: 'h1' }}
        className={classes.layoutTitle}>
        {title}
      </Typography>
    </Fade>
  );
}

LayoutTitle.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

LayoutTitle.defaultProps = {
  classes: {},
};

export default withStyles(styles)(LayoutTitle);
